import React from "react"
import { ButtonLink } from "../structure/theme/button.component"
import styled from "styled-components"
import { useViewPageTracking } from "../thirdParties/gtm/tagManager"
import { useTranslation } from "../translation/translate.component"
import Block from "../../components/block/block.component"

const Text = styled.p`
  text-align: center;
`

const BackButton = styled.div`
  margin-top: 30px;
  text-align: center;
`

export default function ContactConfirmationPage () {
  const { t } = useTranslation()
  useViewPageTracking(`Contacts`, ``, `Confirmation envoi`)

  return (
    <Block blockTitle={t(`common:contact.page_confirmation_title`)} blockTitleSeo="h1">
      <div data-testid="contact_confirmation_message">
        <Text>{t(`common:contact.page_confirmation_email`)}</Text>
        {/* TODO: customize hours via config ? */}
        <Text>{t(`common:contact.page_confirmation_hours`)}</Text>
        {/* TODO: use webcallback feature flag ? */}
        <Text>{t(`common:contact.page_confirmation_webcallback`)}</Text>
      </div>
      <BackButton>
        <ButtonLink route="home">{t(`common:contact.page_confirmation_go_back_home`)}</ButtonLink>
      </BackButton>
    </Block>
  )
}
